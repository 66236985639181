<template>
  <div>
    <AppCarousel
      :cards="musPlaylists"
      type="playlist"
      @openSongList="openSongListModal"
      v-if="$route.path === '/music'"
    />
    <AppCarousel
      :cards="playlists"
      type="playlist"
      @openSongList="openSongListModal"
      v-if="$route.path !== '/music' && playlists.length > 0"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  const SongListModal = () => import('./SongListModal');
  export default {
    name     : 'PlaylistBannerCarousel',
    computed : {
      ...mapGetters({
        playlists    : 'playlist/playlistBanners',
        musPlaylists : 'playlist/musPlaylistBanners',
      }),
    },
    components : {
      'AppCarousel' : () => import('../../components/carousel/AppCarousel'),
    },
    methods : {

      /**
       * Open Song List Modal
       * @param data
       */
      openSongListModal(data) {
        this.$modal.show(SongListModal, {
          playlist : data,
        }, {
          height       : 'auto',
          width        : '900px',
          adaptive     : true,
          clickToClose : false,
          classes      : ['playlist-modal'],
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";
</style>